module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/*":["Referrer-Policy: strict-origin"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://granitecityroofing.api.harnessup.com/wp/graphql","schema":{"typePrefix":"Wp_","perPage":20,"requestConcurrency":20,"previewRequestConcurrency":5,"timeout":120000,"queryDepth":15,"circularQueryLimit":5},"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"CorrectiveAction":{"exclude":true},"Project":{"exclude":true},"ToolboxTalk":{"exclude":true},"Certification":{"exclude":true},"IncidentReport":{"exclude":true},"Equipment":{"exclude":true},"Photo":{"exclude":true},"GfSubmittedEntry":{"exclude":true},"GfEntry":{"exclude":true},"EntryId":{"exclude":true},"Page":{"exclude":true},"Post":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SafetyHQ","short_name":"SafetyHQ","start_url":"/","background_color":"#efefef","theme_color":"#0E5814","display":"standalone","icon":"src/images/safety-hq-favicon.png","icon_options":{"purpose":"maskable any"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0d5cef2b9314d5dbc96b07be3889cc8f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]},"precachePages":["/","/login/","/help/","/password-reset/","/password-reset-request/","/404/","/settings/","/forms/**/**/**/*","/photos/*","/projects/*","/workers/*","/documents/*","/corrective-actions/*","/lessons/**/**/*","/equipment/","/toolbox-talks/","/toolbox-talk/","/certifications/"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
